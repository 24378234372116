.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  color: #333333;
  padding: 6px 18px 10px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 1px solid #333333;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.button:hover,
.button:focus {
  color: #3D9FD6;
  border-color: #3D9FD6;
}
@media (max-width: 1023px) {
  .button {
    padding: 8px 18px 12px;
  }
}
.section--slider .desk {
  max-width: calc(100% - 172px);
}
@media (max-width: 1023px) {
  h2 {
    font-size: 34px;
  }
  h3 {
    font-size: 28px;
  }
  .loud {
    font-size: 24px;
  }
}
.section--three .area {
  margin-top: 37px;
  margin-bottom: 37px;
}
.moodcontent .area {
  margin-top: 15px;
}
.section--one .area .flat .pict {
  margin-top: 45px;
  margin-bottom: 45px;
}
.section--one .area .flat .pict + .pict {
  margin-top: -43px;
}
.section--three .area {
  width: 100%;
}
.section--three .area > .unit {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.section--three .area .cb-album .body,
.section--three .area .head,
.section--three .area .foot,
.section--three .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--three .area > .slim {
  width: 46%;
}
.section--three .area > .slim .head,
.section--three .area > .slim .foot,
.section--three .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--three .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--three .area > .slim .tiny {
  width: 100%;
}
.section--four .area {
  width: 100%;
}
.section--four .area > .unit {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.section--four .area .cb-album .body,
.section--four .area .head,
.section--four .area .foot,
.section--four .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim {
  width: 29.33333333%;
}
.section--four .area > .slim .head,
.section--four .area > .slim .foot,
.section--four .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim .tiny {
  width: 100%;
}
@media (max-width: 1023px) {
  .section--two .area {
    width: 104%;
    margin-left: -2%;
  }
}
.section--three .area {
  width: 104%;
  margin-left: -2%;
}
.section--four .area {
  width: 104%;
  margin-left: -2%;
}
@media (max-width: 1023px) {
  .section--two .area .unit {
    width: 46%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .section--two .area .unit.flat {
    width: 96%;
  }
}
/*# sourceMappingURL=./screen-medium.css.map */